// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-consolidacion-de-conocimiento-y-liderazgo-jsx": () => import("./../../../src/pages/blog/consolidacion-de-conocimiento-y-liderazgo.jsx" /* webpackChunkName: "component---src-pages-blog-consolidacion-de-conocimiento-y-liderazgo-jsx" */),
  "component---src-pages-blog-etapa-de-emprendimiento-jsx": () => import("./../../../src/pages/blog/etapa-de-emprendimiento.jsx" /* webpackChunkName: "component---src-pages-blog-etapa-de-emprendimiento-jsx" */),
  "component---src-pages-blog-etapa-de-evolucion-jsx": () => import("./../../../src/pages/blog/etapa-de-evolucion.jsx" /* webpackChunkName: "component---src-pages-blog-etapa-de-evolucion-jsx" */),
  "component---src-pages-blog-fabrica-de-carrocerias-lider-en-el-sur-del-peru-jsx": () => import("./../../../src/pages/blog/fabrica-de-carrocerias-lider-en-el-sur-del-peru.jsx" /* webpackChunkName: "component---src-pages-blog-fabrica-de-carrocerias-lider-en-el-sur-del-peru-jsx" */),
  "component---src-pages-blog-inicio-de-la-fabrica-de-carrocerias-jsx": () => import("./../../../src/pages/blog/inicio-de-la-fabrica-de-carrocerias.jsx" /* webpackChunkName: "component---src-pages-blog-inicio-de-la-fabrica-de-carrocerias-jsx" */),
  "component---src-pages-blog-inicio-de-una-carrera-en-carrocerias-jsx": () => import("./../../../src/pages/blog/inicio-de-una-carrera-en-carrocerias.jsx" /* webpackChunkName: "component---src-pages-blog-inicio-de-una-carrera-en-carrocerias-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blog-que-es-lo-que-trae-el-futuro-para-intramet-jsx": () => import("./../../../src/pages/blog/que-es-lo-que-trae-el-futuro-para-intramet.jsx" /* webpackChunkName: "component---src-pages-blog-que-es-lo-que-trae-el-futuro-para-intramet-jsx" */),
  "component---src-pages-bus-interprovincial-jsx": () => import("./../../../src/pages/bus/interprovincial.jsx" /* webpackChunkName: "component---src-pages-bus-interprovincial-jsx" */),
  "component---src-pages-bus-interprovincial-olympo-36-jsx": () => import("./../../../src/pages/bus/interprovincial/olympo36.jsx" /* webpackChunkName: "component---src-pages-bus-interprovincial-olympo-36-jsx" */),
  "component---src-pages-bus-para-personal-de-mina-jsx": () => import("./../../../src/pages/bus/para-personal-de-mina.jsx" /* webpackChunkName: "component---src-pages-bus-para-personal-de-mina-jsx" */),
  "component---src-pages-bus-para-personal-de-mina-olympo-29-jsx": () => import("./../../../src/pages/bus/para-personal-de-mina/olympo29.jsx" /* webpackChunkName: "component---src-pages-bus-para-personal-de-mina-olympo-29-jsx" */),
  "component---src-pages-bus-para-personal-de-mina-olympo-31-jsx": () => import("./../../../src/pages/bus/para-personal-de-mina/olympo31.jsx" /* webpackChunkName: "component---src-pages-bus-para-personal-de-mina-olympo-31-jsx" */),
  "component---src-pages-bus-para-personal-de-mina-olympo-325-jsx": () => import("./../../../src/pages/bus/para-personal-de-mina/olympo325.jsx" /* webpackChunkName: "component---src-pages-bus-para-personal-de-mina-olympo-325-jsx" */),
  "component---src-pages-bus-para-personal-de-mina-olympo-34-jsx": () => import("./../../../src/pages/bus/para-personal-de-mina/olympo34.jsx" /* webpackChunkName: "component---src-pages-bus-para-personal-de-mina-olympo-34-jsx" */),
  "component---src-pages-bus-para-personal-de-mina-olympo-35-jsx": () => import("./../../../src/pages/bus/para-personal-de-mina/olympo35.jsx" /* webpackChunkName: "component---src-pages-bus-para-personal-de-mina-olympo-35-jsx" */),
  "component---src-pages-bus-urbano-jsx": () => import("./../../../src/pages/bus/urbano.jsx" /* webpackChunkName: "component---src-pages-bus-urbano-jsx" */),
  "component---src-pages-bus-urbano-olympo-jsx": () => import("./../../../src/pages/bus/urbano/olympo.jsx" /* webpackChunkName: "component---src-pages-bus-urbano-olympo-jsx" */),
  "component---src-pages-buses-jsx": () => import("./../../../src/pages/buses.jsx" /* webpackChunkName: "component---src-pages-buses-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-garantia-y-repuestos-jsx": () => import("./../../../src/pages/garantia-y-repuestos.jsx" /* webpackChunkName: "component---src-pages-garantia-y-repuestos-jsx" */),
  "component---src-pages-gracias-jsx": () => import("./../../../src/pages/gracias.jsx" /* webpackChunkName: "component---src-pages-gracias-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-institucional-jsx": () => import("./../../../src/pages/institucional.jsx" /* webpackChunkName: "component---src-pages-institucional-jsx" */),
  "component---src-pages-mantenimiento-y-asesoria-jsx": () => import("./../../../src/pages/mantenimiento-y-asesoria.jsx" /* webpackChunkName: "component---src-pages-mantenimiento-y-asesoria-jsx" */),
  "component---src-pages-trabaja-con-nosotros-jsx": () => import("./../../../src/pages/trabaja-con-nosotros.jsx" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-jsx" */)
}

